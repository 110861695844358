<template>
  <div class="conflict-sidebar">
    <div class="conflict-sidebar--wrapper">
      <div class="conflict-sidebar--h4">
        Updating this reservation with your selected criteria would cause the
        hard conflicts below. Are you sure you want to proceed?
      </div>
      <CRButton
        class="conflict-sidebar--action-btn"
        style="position: fixed; bottom: 71px"
        color="white"
        @click="cancelSubmitDueToConflict"
      >
        <span :style="`color: ${$cr.theme.primary};`">No, Go Back</span>
      </CRButton>
      <CRButton
        class="conflict-sidebar--action-btn"
        style="position: fixed; bottom: 0px"
        color="red"
        @click="submitUpdate"
      >
        <span v-if="!loading">Update Reservation With Conflict</span>
        <CRProgressCircular v-else :size="27" />
      </CRButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cancelSubmitDueToConflict: { type: Function, default: () => {} },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    submitUpdate() {
      this.loading = true
    },
  },
}
</script>

<style lang="scss" scoped>
.conflict-sidebar {
  display: flex;
  flex-direction: column;
  width: 500px;

  h4 {
    font-size: 24px;
  }

  p {
    margin: 0;
  }

  &--action-btn {
    display: flex;
    flex-direction: column;
    background: $primary;
    font-size: 18px;
    color: white;
    width: 500px;
    height: 71px !important;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0;
    transition: 0.1s all ease-in-out;

    &:disabled {
      background: $gray-light;
    }
  }

  &--wrapper {
    flex: 1;
  }

  &--wrapper > div {
    margin: 40px;
  }
}
</style>
